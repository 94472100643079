<template>
  <b-modal header-class="ion-bg-modal-header" size="lg"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('chargeBox.connector') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="ct-update-cb-id">{{ $t('chargeBox.chargeBoxId') }}</label>
            <b-form-input class="form-control" type="text" id="ct-update-cb-id" v-model="form.chargeBoxId" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="ct-update-id">{{ $t('chargeBox.connectorId') }}</label>
            <b-form-input class="form-control" type="text" id="ct-update-id"
                          v-model="form.connectorId" disabled />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="ct-update-link-id">{{ $t('chargeBox.connectorLinkedId') }}</label>
            <b-form-input class="form-control" type="text" id="ct-update-link-id"
                          v-model="form.connectorLinkedId" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="ct-update-ct-type">
              <span>{{ $t('chargeBox.connectorType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="ct-update-ct-type"
                         v-model="form.connectorType"
                         label="text" track-by="value"
                         openDirection="top"
                         :options="connectorTypeOptions"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         :class="{ 'is-invalid': submitted && $v.form.connectorType.$error }" />
            <div v-if="submitted && $v.form.connectorType.$error" class="invalid-feedback">
              <span v-if="!$v.form.connectorType.required">{{ $t('validation.valueIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="ct-update-created">{{ $t('chargeBox.statusUpdateDate') }}</label>
            <b-form-input class="form-control" type="text" id="ct-update-created" v-model="form.statusUpdateTimestamp" disabled />
          </div>
        </div>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      submitted: false,
      item: null,
      connectorTypeOptions: [],
      form: {
        chargeBoxId: null,
        connectorId: null,
        connectorLinkedId: null,
        connectorType: null,
        created: null
      }
    }
  },
  validations: {
    form: {
      connectorId: { required },
      connectorLinkedId: { required },
      connectorType: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getItem();
      vm.getConnectorTypeOptions();
      vm.visible = true;
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/Connector/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);
        vm.form = {...vm.form, connectorType: { 'text': vm.item.connectorType, 'value': vm.item.connectorType }}
      }).catch((err) => {
        console.error('Connector Retrieve Error !!', err)
      });
    },
    getConnectorTypeOptions() {
      const vm = this;
      vm.connectorTypeOptions = Object.entries(this.$_app.code.connectorType).map(([key, value]) => ({ text: key, value: value }));
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.submitted = false;
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        chargeBoxId: null,
        connectorId: null,
        connectorLinkedId: null,
        connectorType: null,
        statusUpdateTimestamp: null
      };
      vm.connectorTypeOptions = [];
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/Connector/update';

      let _form = {
        id: this.selectedId,
        chargeBoxId: vm.form.chargeBoxId,
        connectorId: vm.form.connectorId,
        connectorType: vm.form.connectorType.value,
        connectorLinkedId: vm.form.connectorLinkedId
      };

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Connector Edit Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
