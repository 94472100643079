<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ modalTitle }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">
        <b-overlay :show="!items" variant="transparent" opacity="1" blur="5px" rounded="sm">
          <div class="card mb-0">
            <div class="card-body bg-black text-white">
              <div v-if="items.length > 0">
                <div v-for="item in items" :key="item.id">
                  <span class="ct-padding-right-100">{{ item.created }}</span>
                  <span class="ct-padding-right-100">[{{ item.chargeBoxId }}]</span>
                  <span class="ct-padding-right-100">[{{ item.msgType }}]</span>
                  <span>{{ item.msg }}</span>
                </div>
              </div>
              <div v-else style="height: 92vh">
                <span>{{ $t('chargeBox.noLog') }}</span>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="secondary" class="float-end w-xs p-1" @click="close">닫기</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';

export default {
  props: {
    logInfo: Object
  },
  computed: {
    modalTitle() {
      const boxName = this.logInfo.chargeBoxName;
      const boxId = this.logInfo.chargeBoxId;
      return `Log : ${boxName} (${boxId})`;
    }
  },
  data() {
    return {
      visible: false,
      searchConditions: [],
      pageLimit: 500,
      items: [],
      realLog: true,
      count: 0
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.realLog = setInterval(vm.getItems, 3000);
      window.addEventListener('scroll', function() {
        vm.detectBottom();
      })

      vm.visible = true;
    },
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/OcppLog/logViewList';

      vm.searchConditions = [
        { searchKey: 'chargeBoxId', searchValue: this.logInfo.chargeBoxId }
      ];

      http.post(_url, {
        page: 1,
        limit: vm.pageLimit,
        searchConditions: vm.searchConditions
      }).then(res => {
        vm.items = res.data.items;
      }).then(res => {
        if (vm.count === 0 && vm.items.length > 0) {
          window.scrollTo({ top: 99999, behavior: 'instant'});
          vm.count = 1;
        }
      }).catch(err => {
        console.error('Log List Error :: ', err)
      });
    },
    close() {
      const vm = this;
      clearInterval(vm.realLog);

      vm.items = [];
      vm.count = 0;
      vm.visible = false;
    },
    detectBottom() {
      let scrollTop = document.documentElement.scrollTop;
      let innerHeight = window.innerHeight;
      let scrollHeight = document.body.scrollHeight;

      if (scrollTop + innerHeight >= scrollHeight) {
        return true;
      } else {
        window.scrollTo({ top: scrollTop, behavior: 'instant'});
        return false;
      }
    }
  }
}
</script>
