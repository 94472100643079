<template>
  <div class="card">
    <div class="card-body">
      <div class="fw-bold" :style="{ color: titleColor }">
        {{ title }}
      </div>
      <div class="row mt-3">
        <div v-for="item in items" class="col" :class="item.divide === true ? 'right-dvider' : '' ">
          <div class="text-center">
            <div class="text-muted" :style="{ color: nameColor }">{{ item.name }}</div>
            <div>
              <span class="font-size-26 fw-bold" :style="{ color: item.highlightColor ?? valueColor }">{{ item.value.toLocaleString() }}</span>
              <span>{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCard",
  props: {
    title: String,
    items: Array, // ex) [{ name: '전체', value: 100, divide: true, highlightColor: 'red' }]
    titleColor: String,
    nameColor: String,
    valueColor: { type: String, default: 'black' },
  },
}
</script>

<style>
.right-dvider {
    border-right: #b7babd solid 4px;
}
</style>