<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('chargeBox.cbTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <!-- Charge Box Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('chargeBox.cbInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-name">
              <span>{{ $t('chargeBox.chargeBoxName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cb-update-name"
                          v-model="form.chargeBoxName"
                          :class="{ 'is-invalid': submitted && $v.form.chargeBoxName.$error }" />
            <div v-if="submitted && $v.form.chargeBoxName.$error" class="invalid-feedback">
              <span v-if="!$v.form.chargeBoxName.required">{{ $t('validation.cbNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-id">
              <span>{{ $t('chargeBox.chargeBoxId') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cb-update-id"
                          v-model="form.chargeBoxId"
                          :class="{ 'is-invalid': submitted && $v.form.chargeBoxId.$error }" />
            <div v-if="submitted && $v.form.chargeBoxId.$error" class="invalid-feedback">
              <span v-if="!$v.form.chargeBoxId.required">{{ $t('validation.cbIdIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-charger-type">
              <span>{{ $t('chargeBox.chargerType') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="typeBtn1" autocomplete="off"
                     v-model="form.chargerType" value="SLOW" />
              <label class="btn btn-outline-secondary" for="typeBtn1">{{ $t('common.slow') }}</label>
              <input type="radio" class="btn-check" id="typeBtn2" autocomplete="off"
                     v-model="form.chargerType" value="FAST" />
              <label class="btn btn-outline-secondary" for="typeBtn2">{{ $t('common.fast') }}</label>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-charger-type">
              <span>{{ $t('common.chargeSpeed') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="btn-group w-100" role="group">
              <template v-if="form.chargerType === 'SLOW'">
                <div class="btn-group" role="group" style="width: 33%">
                  <input type="radio" class="btn-check" id="maxKwBtn1" autocomplete="off"
                         v-model="form.maxKw" value="10" />
                  <label class="btn btn-outline-secondary" for="maxKwBtn1">{{ '10 kW' }}</label>
                </div>
              </template>
              <template v-else-if="form.chargerType === 'FAST'">
                <input type="radio" class="btn-check" id="maxKwBtn1" autocomplete="off"
                       v-model="form.maxKw" value="90" />
                <label class="btn btn-outline-secondary" for="maxKwBtn1">{{ '90 kW' }}</label>
                <input type="radio" class="btn-check" id="maxKwBtn2" autocomplete="off"
                       v-model="form.maxKw" value="120" />
                <label class="btn btn-outline-secondary" for="maxKwBtn2">{{ '120 kW' }}</label>
                <input type="radio" class="btn-check" id="maxKwBtn3" autocomplete="off"
                       v-model="form.maxKw" value="180" />
                <label class="btn btn-outline-secondary" for="maxKwBtn3">{{ '180 kW' }}</label>
              </template>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-install-date">{{ $t('chargeBox.installDate') }}</label>
            <div>
              <date-picker type="date"
                           v-model="installDate" class="col-6"
                           :show-time-panel="showTimePanel" />
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-status-change">{{ $t('chargeBox.cbStatusChange') }}</label>
            <div class="form-check form-switch form-switch-lg mb-0" dir="ltr">
              <input type="checkbox" class="form-check-input"
                     id="cb-update-status-change" v-model="isCbStatus" />
              <label class="form-check-label" for="cb-update-status-change">
                {{ isCbStatus ? $t('common.usePossible') : $t('common.useStop') }}
              </label>
            </div>
          </div>
        </div>

        <hr/>

        <div class="row pb-0">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-4">
                <label class="form-col-label" for="cb-update-pwd">{{ $t('common.password') }}</label>
              </div>
              <div class="col-8">
                <b-button variant="warning" class="w-xs p-1" @click="passwordModeChange(true)" v-if="!updatePasswordMode">
                  {{ $t('btn.edit') }}
                </b-button>
                <b-button variant="success" class="w-xs p-1 me-3" @click="pwdSave" v-if="updatePasswordMode">{{ $t('btn.save') }}</b-button>
                <b-button variant="secondary" class="w-xs p-1" @click="passwordModeChange(false)" v-if="updatePasswordMode">
                  {{ $t('btn.cancel') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="updatePasswordMode">
          <div class="col-xl-12">
            <div class="card shadow-none mb-0">
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group label-class="col-form-label"
                                    label-cols="4" content-cols="8"
                                    :label="$t('common.newPwd')" label-for="changePassword">
                        <b-form-input id="changePassword" type="password" autocomplete="off"
                                      v-model="pwdForm.password"
                                      :class="{'is-invalid': $v.pwdForm.password.$error }" />
                        <div v-if="pwdSubmitted && $v.pwdForm.password.$error" id="input-pwd-live-feedback" class="invalid-feedback">
                          <span v-if="!$v.pwdForm.password.required">{{ $t('validation.pwdIsRequired') }}</span>
                        </div>
                      </b-form-group>
                    </div>

                    <div class="col-xl-6">
                      <b-form-group label-class="col-form-label"
                                    label-cols="4" content-cols="8"
                                    :label="$t('common.newPwdCheck')" label-for="changePasswordConfirm">
                        <b-form-input id="changePasswordConfirm" type="password" autocomplete="off"
                                      v-model="pwdForm.confirmPassword"
                                      :class="{'is-invalid': $v.pwdForm.confirmPassword.$error }" />
                        <div v-if="pwdSubmitted && $v.pwdForm.confirmPassword.$error" id="input-confirm-pwd-live-feedback" class="invalid-feedback">
                          <span v-if="!$v.pwdForm.confirmPassword.required">{{ $t('validation.pwdIsRequired') }}</span>
                          <span v-if="pwdForm.confirmPassword && !$v.pwdForm.confirmPassword.sameAsPassword">{{ $t('validation.pwdCheckPlease') }}</span>
                        </div>
                      </b-form-group>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <!-- Charge Box Info End -->

        <!-- Headquarters & Station Info Start -->
        <div class="ion-modal-form-group-title">{{ $_app.user.roles[0] === 'ADMIN' ? $t('chargeBox.hqAndCpInfo') : $t('chargeBox.cpInfo') }}</div>
        <div class="row mb-3" v-if="$_app.user.roles[0] === 'ADMIN'">
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-hq">{{ $t('chargeBox.hqName') }}</label>
            <multiselect id="cb-update-hq"
                         v-model="hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         openDirection="top"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true"
                         :disabled="$_app.user.roles[0] === 'HQ'"
                         @select="selectHq"
                         @remove="removeHq" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-hq-manager">{{ $t('chargeBox.hqManagerName') }}</label>
            <b-form-input class="form-control" type="text" id="cb-update-hq-manager" v-model="hqManagerName" disabled />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-cp">{{ $t('common.cp') }}</label>
            <multiselect id="cb-update-cp"
                         v-model="cpId"
                         label="text" track-by="value"
                         :options="cpOptions"
                         openDirection="top"
                         :placeholder="$t('alert.selectCp')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true"
                         @select="selectCp"
                         @remove="removeCp" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cb-update-cp-manager">{{ $t('chargeBox.cpManagerName') }}</label>
            <b-form-input class="form-control" type="text" id="cb-update-cp-manager" v-model="cpManagerName" disabled />
          </div>
        </div>
        <!-- Headquarters & Station Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save" :disabled="updatePasswordMode">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      submitted: false,
      pwdSubmitted: false,
      updatePasswordMode: false,
      hqOptions: [],
      cpOptions: [],
      form: {
        chargeBoxName: null,
        chargeBoxId: null,
        chargerType: null,
        maxKw: '10',
        password: null
      },
      pwdForm: {
        password: null,
        confirmPassword: null
      },
      hqId: null,
      hqManagerName: null,
      cpId: null,
      cpManagerName: null,
      installDate: null,
      item: null,
      showTimePanel: false,
      isCbStatus: true
    }
  },
  validations: {
    form: {
      chargeBoxName: { required },
      chargeBoxId: { required }
    },
    pwdForm: {
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs('password') }
    }
  },
  watch: {
    'form.chargerType': {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          const vm = this;
          vm.form.maxKw = newVal === 'SLOW' ? '10' : '90';
        }
      },
      immediate: false,
      deep: true,
    },
    isCbStatus(newValue) {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/changeAvailability';

      let _form = {
        chargePointSelectList: [ { chargeBoxId: vm.form.chargeBoxId } ],
        connectorId: 0,
        availType: newValue ? 'OPERATIVE' : 'INOPERATIVE'
      };

      http.post(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.cbStatusChanged'),
            confirmButtonText: i18n.t('btn.close')
          });
        }
      }).catch(err => {
        console.error('Charge Box Status Change Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({
            text: item.hqName,
            value: item.id,
            manager: item.hqRepresentAccount ? item.hqRepresentAccount.name : '-'
          });
        });

        vm.getItem();
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    selectHq() {
      const vm = this;

      if (!this.$_utils.isEmpty(vm.hqId)) {
        vm.hqManagerName = vm.hqId.manager;
      } else {
        vm.hqManagerName = null;
      }

      vm.getCpOptions();
    },
    removeHq() {
      const vm = this;
      vm.hqManagerName = null;

      vm.cpId = null;
      vm.cpManagerName = null;
      vm.cpOptions = [];
    },
    selectCp() {
      const vm = this;

      if (!this.$_utils.isEmpty(vm.cpId)) {
        vm.cpManagerName = vm.cpId.manager;
      } else {
        vm.cpManagerName = null;
      }
    },
    removeCp() {
      const vm = this;
      vm.cpManagerName = null;
    },
    getCpOptions(type) {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.cpOptions = [];
      vm.cpId = null;
      vm.cpManagerName = null;

      if (!this.$_utils.isEmpty(vm.hqId)) {
        http.post(_url, {
          page: 1,
          limit: 1000,
          searchConditions: [{ 'searchKey': 'hqId', 'searchValue': vm.hqId.value }],
          sortCondition: { sortDirection: 'DESC' }
        }).then(res => {
          res.data.items.forEach(item => {
            vm.cpOptions.push({
              text: item.placeName,
              value: item.placeId,
              manager: item.placeRepresentAccount ? item.placeRepresentAccount.name : '-'
            });
          });
          if (!this.$_utils.isEmpty(type)) {
            vm.cpId = vm.cpOptions.find(item => item.value === type);
            vm.cpManagerName = vm.cpId.manager;
          }
        }).catch(err => {
          console.error('Charge Place List Error :: ', err)
        });
      }
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/ChargePoint/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);

        if (!this.$_utils.isEmpty(vm.item.chargePlace)) {

          if (vm.item.chargePlace.hqBusiness) {
            vm.hqId = vm.hqOptions.find(item => item.value === vm.item.chargePlace.hqBusiness.id);
            vm.hqManagerName = vm.hqId.manager;
            vm.getCpOptions(vm.item.chargePlace.placeId);
          }
        }

        if (vm.item.installationDate && !this.$_utils.isEmpty(vm.item.installationDate)) {
          vm.installDate = new Date(vm.item.installationDate);
        }
      }).catch((err) => {
        console.error('Charge Box Retrieve Error !!', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.submitted = false;
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;
      vm.showTimePanel = false;

      vm.form = {
        chargeBoxName: null,
        chargeBoxId: null,
        chargerType: null,
        maxKw: '10',
        password: null
      }
      vm.hqOptions = [];
      vm.cpOptions = [];

      vm.hqId = null;
      vm.hqManagerName = null;
      vm.cpId = null;
      vm.cpManagerName = null;

      vm.pwdForm = {
        password: null,
        confirmPassword: null
      }
      vm.pwdSubmitted = false;
      vm.updatePasswordMode = false;
      vm.installDate = null;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/update';

      let _form = Object.assign({}, vm.form);

      if (!this.$_utils.isEmpty(vm.cpId)) {
        _form.chargePlace = {};
        _form.chargePlace.placeId = vm.cpId.value;
      } else {
        _form.chargePlace = {};
        _form.chargePlace.placeId = null;
      }

      if (!this.$_utils.isEmpty(vm.installDate)) {
        _form.installationDate = this.$moment(vm.installDate).format('YYYY-MM-DD HH:mm:ss');
      }

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Charge Box Update Error :: ', err)
      });
    },
    passwordModeChange(status) {
      const vm = this;

      if (status) {
        vm.updatePasswordMode = true;
      } else {
        vm.updatePasswordMode = false;
        vm.pwdSubmitted = false;
        vm.$nextTick(() => {
          this.$v.pwdForm.$reset();
        });
      }
    },
    pwdSave() {
      const vm = this;
      vm.pwdSubmitted = true;

      this.$v.pwdForm.$touch();

      if (!this.$v.pwdForm.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmPwd'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.pwdSaveItems();
          }
        });
      }
    },
    pwdSaveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/updatePassword';

      let _form = {
        id: vm.item.id,
        password: vm.pwdForm.password
      };

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });

          vm.updatePasswordMode = false;
          vm.pwdForm = {
            password: null,
            confirmPassword: null
          };
        }
      }).catch(err => {
        console.error('Password Update Error :: ', err)
      });
    },
    handleOpenChange() {
      const vm = this;
      vm.showTimePanel = false;
    },
    toggleTimePanel() {
      const vm = this;
      vm.showTimePanel = !vm.showTimePanel;
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
