<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ modalTitle }}</div>
    </template>

    <!-- Search Area Start -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <!-- // Search Input -->
              <div class="col-xl-10 ion-mb-mb-100">
                <div class="row">
                  <div class="col-xl-3">
                    <label class="form-label" for="cb-search-period">{{ $t('common.searchDate') }}</label>
                    <div>
                      <date-picker id="searchDate"
                                   v-model="searchDate"
                                   format="YYYY-MM-DD"
                                   :disabled-date="notAfterTodayTwelveOClock" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- // Search Button -->
              <div class="col-xl-2 align-content-end">
                <div class="float-end">
                  <b-button variant="primary" @click="search">
                    <i class="uil uil-search me-2"></i>
                    <span>{{ $t('btn.search') }}</span>
                  </b-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Search Area End -->

    <div class="row">
      <div class="col-xl-12">
        <b-overlay :show="!items" variant="transparent" opacity="1" blur="5px" rounded="sm">
          <div class="card mb-0">
            <div class="card-body bg-black text-white">
              <div v-if="items.length > 0">
                <div v-for="item in items" :key="item.id">
                  <span class="ct-padding-right-100">{{ item.created }}</span>
                  <span class="ct-padding-right-100">[{{ item.chargeBoxId }}]</span>
                  <span class="ct-padding-right-100">[{{ item.msgType }}]</span>
                  <span>{{ item.msg }}</span>
                </div>
              </div>
              <div v-else style="height: 50vh">
                <span>{{ $t('chargeBox.noLog') }}</span>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="secondary" class="float-end w-xs p-1" @click="close">닫기</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Multiselect from 'vue-multiselect';

export default {
  components: {Multiselect},
  props: {
    logInfo: Object
  },
  computed: {
    modalTitle() {
      const boxName = this.logInfo.chargeBoxName;
      const boxId = this.logInfo.chargeBoxId;
      return `Log : ${boxName} (${boxId})`;
    }
  },
  data() {
    return {
      visible: false,
      searchConditions: [],
      searchDate: new Date(this.$moment().subtract(1, 'd')),
      pageLimit: 8000,
      items: []
    }
  },
  methods: {
    show() {
      const vm = this;
      vm.getItems();

      vm.visible = true;
    },
    search() {
      const vm = this;
      vm.getItems();
    },
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/OcppLog/logViewList';

      const _searchDate = this.$moment(vm.searchDate).format('YYYY-MM-DD');

      vm.searchConditions = [
        {searchKey: 'chargeBoxId', searchValue: this.logInfo.chargeBoxId},
        {searchKey: 'startDate', searchValue: _searchDate},
        {searchKey: 'endDate', searchValue: _searchDate},
      ];

      http.post(_url, {
        page: 1,
        limit: vm.pageLimit,
        searchConditions: vm.searchConditions
      }).then(res => {
        vm.items = res.data.items;
      }).then(() => {
        //vm.visible = true;
      }).catch(err => {
        console.error('Log List Error :: ', err)
      });
    },
    notAfterTodayTwelveOClock(date) {
      return date > new Date(new Date().setHours(12, 0, 0, 0));
    },
    close() {
      const vm = this;

      vm.items = [];
      vm.visible = false;
    }
  }
}
</script>